<template>
  <div class="provincialDataInterface">
    <v-list
      ref="list"
      :isAdd="true"
      :border="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :operateColumnWidth="150"
      operateColumnAlign="center"
      v-bind="submitConfig"
    >
      <template #searchOperaSlot>
        <v-button text="返回" :width="112" @click="toBack"
      /></template>
      <template #searchSlot>
        <!-- <v-select
          clearable
          :options="tenantList"
          v-model="searchParam.tenantId"
          @change="$refs.list.search()"
          :placeholder="'请选择'"
          label="社区"
        /> -->
        <v-select
          clearable
          :options="isSucceedStatusOps"
          v-model="submitConfig.postData.queryData.responseResult"
          @change="$refs.list.search()"
          :placeholder="'请选择'"
          label="上报结果"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" @click="toView(scope.row)" />
      </template>
    </v-list>
    <!-- 弹窗 -->
    <v-dialog
      title="查看"
      v-model="viewDialog"
      :close-on-click-modal="false"
      @confirm="confirm"
      :appendToBody="true"
      :isShowCancelBtn="false"
      :center="false"
      sureTxt="关闭"
    >
      <div class="json">
        <vue-json-editor
          v-model="resultInfo"
          :showBtns="false"
          :mode="'code'"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getReportData, getTenantList } from "./api.js";
import { mapState } from "vuex";
import { isSucceedStatusMap, isSucceedStatusOps } from "./map.js";
import { _localStorage } from "@/utils/utils.js";
import vueJsonEditor from "vue-json-editor";
import { createAlinkVNodeHtml, createSpanVNodeHtml } from "@/utils/utils.js";
import { mapHelper } from "@/utils/common.js";
export default {
  name: "provincialDataInterface",
  data() {
    return {
      title: "",
      resultInfo: null,
      viewDialog: false,
      submitConfig: {
        methods: "post",
        postData: {
          id: "",
          url: "",
          tenantId: "",
          queryData: {},
          // mapper: "OpsSzdzPartyOrganizationInformationMapper",
          // url: "szdz/outApi/partyOrganizationInfo/add",
          // queryData: {},
        },
      },
      searchParam: {
        openUrl: "",
        tenantId: "",
        resultStatus: null,
      },
      tableUrl: getReportData,
      headers: [
        {
          prop: "tenantId",
          label: "社区名称",
          align: "center",
          showOverflowTooltip: true,
          width: 200,
          formatter: (row, prop) => {
            return this.formatTenantId(row, prop);
          },
        },
        // {
        //   prop: "methodName",
        //   label: "接口名称",
        //   align: "center",
        //   width: 300,
        //   showOverflowTooltip: true,
        // },
        {
          prop: "insertTime",
          label: "上报时间",
          align: "center",
          width: 300,
        },
        {
          prop: "responseResult",
          label: "结果",
          align: "center",
          width: 150,
          formatter: (row, prop) => {
            return createSpanVNodeHtml(this, row, prop);
          },
        },
        {
          prop: "errorMessage",
          label: "错误信息",
          align: "center",
          showOverflowTooltip: true,
          formatter: (row, prop) => {
            return row[prop] ? row[prop] : "--";
          },
        },
        // {
        //   prop: "requestData",
        //   label: "上报数据",
        //   align: "center",
        //   formatter: (row, prop) =>
        //     createAlinkVNodeHtml(this, row, prop, {
        //       txtView: row[prop] ? "查看" : "",
        //       cb: () => this.toView(row, prop),
        //     }),
        // },
        // {
        //   prop: "responseData",
        //   label: "返回数据",
        //   align: "center",
        //   formatter: (row, prop) =>
        //     createAlinkVNodeHtml(this, row, prop, {
        //       txtView: row[prop] ? "查看" : "",
        //       cb: () => this.toView(row, prop),
        //     }),
        // },
      ],
      isSucceedStatusOps: isSucceedStatusOps(),
      tenantList: [],
    };
  },
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.$store.commit("app/setAccountInfo", userInfo);
    }
    const { id, url, tenantId } = this.$route.query;
    if (id) {
      this.submitConfig.postData.id = id;
      this.submitConfig.postData.url = url;
      this.submitConfig.postData.tenantId = tenantId;
    }
    this.getTenantList();
  },
  mounted() {
    this.$setBreadList("查看上报日志");
  },
  components: {
    vueJsonEditor,
  },
  computed: {
    ...mapState("app", ["accountInfo"]),
    tenantIdOpt() {
      let arr = [];
      if (
        this.accountInfo.tenantIds &&
        this.accountInfo.tenantIds.split(",").length >= 1
      ) {
        arr = this.accountInfo.tenantIds.split(",");
        const tenantNames = this.accountInfo.tenantNames.split(",");
        arr = arr.map((item, index) => {
          return { value: item, label: tenantNames[index] };
        });
      }
      return arr;
    },
  },
  methods: {
    //格式社区名称
    formatTenantId(row, prop) {
      const { map: tenantNamedMap } = mapHelper.setMap(this.tenantList);
      return tenantNamedMap[row[prop]];
    },
    //获取社区编码列表
    getTenantList() {
      const params = {
        tenantId: this.accountInfo.tenantId,
      };
      this.$axios
        .post(getTenantList, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            console.log(data);
            data.forEach((item) => {
              item.value = item.tenantId;
              item.label = item.tenantName;
            });
            this.tenantList = data;
          }
        });
    },
    // 查看数据
    toView(row, prop) {
      this.resultInfo = null;
      let responseData, requestData;
      if (row.responseData) {
        responseData = JSON.parse(row.responseData);
      }
      if (row.requestData) {
        requestData = JSON.parse(row.requestData);
      }
      this.resultInfo = { responseData, requestData };
      this.viewDialog = true;
    },
    //关闭弹窗
    confirm() {
      this.viewDialog = false;
    },
    // 返回
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.provincialDataInterface {
  box-sizing: border-box;
  height: 100%;
  /deep/ .v-table .opera-btn {
    width: 100% !important;
    display: flex;
    justify-content: center;
    .gray {
      .el-button--text {
        color: #999;
      }
    }
  }
  .json {
    height: 500px;
    ::v-deep .jsoneditor-vue {
      height: 500px;
      .jsoneditor-poweredBy {
        display: none;
      }
      .ace_scrollbar {
        width: 5px !important;
      }
    }
  }
  ::v-deep .v-dialog {
    .dialog-footer {
      justify-content: flex-end;
    }
  }
}
</style>
